import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="goldBg">
        <div className="container">
          <InnerWrapper style={{ padding: "30px 0" }}>
            <br />
            <p
              className="whiteColor font20 textLeft"
              style={{ padding: "0 0 0 2rem" }}
            >
              {" "}
              บริษัท สตูดิโอ ดี แฟคโท จำกัด (สำนักงานใหญ่) <br />
              55/104 ซอยลาดพร้าว 88 (อรพิน) ถนนประดิษฐ์มนูธรรม แขวงพลับพลา
              เขตวังทองหลาง กทม. 10310
              <br />
              เบอร์โทร : 066-045-0065
            </p>
            <br />
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  
`;
const InnerWrapper = styled.div`
  width: 100%;

  @media (max-width: 550px) {
    flex-direction: column;
    
  }
`;
