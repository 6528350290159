import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";
import InfoButton from "../Buttons/InfoButton";
// Asset
import SocialMediaLogos from "../../assets/img/socialmedia_logos.png";
import ProjectImg1 from "../../assets/img/projects/1.png";
import ProjectImg2 from "../../assets/img/projects/2.png";
import ProjectImg3 from "../../assets/img/projects/3.png";

export default function Pricing() {
  return (

    <div className="container  greyBg">
    <Wrapper id="pricing">
     
        <div className="  textCenter">
          <HeaderInfo>
            <br />
            <br />
            <p className="font20 whiteColor normal">
              ค่าผลิตวิดีโอโฆษณาสำหรับสื่อออนไลน์
            </p>
            <ImgStyle src={SocialMediaLogos} alt="client logo" />
            <br />
          </HeaderInfo>
          <br />
          <TablesWrapper className="flexSpaceNull scrollmenu">
            <TableBox>
              <PricingTable
                icon="roller"
                duration="6 วินาที"
                price="12,000"
                price2="5,000"
                title="Starter"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "คิด ออกแบบ จัดวาง", cheked: true },
                  {
                    name: "ทำกราฟิกเคลื่อนไหว (Motion Graphic)",
                    cheked: true,
                  },
                  { name: "ตัดต่อวิดีโอ (Editing, Cutdown)", cheked: true },
                  { name: "จบงานพร้อม Upload", cheked: true },
                ]}
                action={() => alert("clicked")}
                color="#72cd2a"
                project={ProjectImg1}
                to="project1"
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                duration="7-15 วินาที"
                price="17,000"
                title="Basic"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "คิด ออกแบบ จัดวาง", cheked: true },
                  {
                    name: "ทำกราฟิกเคลื่อนไหว (Motion Graphic)",
                    cheked: true,
                  },
                  { name: "ตัดต่อวิดีโอ (Editing, Cutdown)", cheked: true },
                  { name: "จบงานพร้อม Upload", cheked: true },
                ]}
                action={() => "to=project1"}
                color="#0da2ff"
                project={ProjectImg2}
                to="project2"
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                duration="16-30 วินาที"
                price="24,000"
                price2="7,000"
                title="Golden"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "คิด ออกแบบ จัดวาง", cheked: true },
                  {
                    name: "ทำกราฟิกเคลื่อนไหว (Motion Graphic)",
                    cheked: true,
                  },
                  { name: "ตัดต่อวิดีโอ (Editing, Cutdown)", cheked: true },
                  { name: "จบงานพร้อม Upload", cheked: true },
                ]}
                action={() => alert("clicked")}
                color="#f82659"
                project={ProjectImg3}
                to="project3"
              />
            </TableBox>
          </TablesWrapper>
          <div style={{ maxWidth: "200px", margin: "50px auto 30px auto" }}>
            <InfoButton color="#b5af97" title="ข้อมูลเพิ่มเติม" />
          </div>
        </div>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  @media (max-width: 786px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 786px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 100%;
  @media (max-width: 786px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;
const ImgStyle = styled.img`
  width: 20%;
  height: 20%;
  padding: 1%;
`;
