import React, { useState } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import Grid from "@mui/material/Grid"; // Grid version 1

// Asset

// Asset
import v1 from "../../assets/video/15sec/1.mp4";
import v2 from "../../assets/video/15sec/2.mp4";
import v3 from "../../assets/video/15sec/3.mp4";
import v4 from "../../assets/video/15sec/5.mp4";
import v5 from "../../assets/video/15sec/4.mp4";
import tv1 from "../../assets/img/15 sec/1.jpg";
import tv2 from "../../assets/img/15 sec/2.jpg";
import tv3 from "../../assets/img/15 sec/3.jpg";
import tv4 from "../../assets/img/15 sec/4.jpg";
import tv5 from "../../assets/img/15 sec/5.jpg";

export default function Project2() {
  return (
    <div className="greyBg textCenter" id="project2">
      <div>
        <Wrapper className="container flexSpaceCenter">
          <TopWrapper className="lightdarkBg  whiteColor">
            <p className="font20 textLeft lightgreyColor"> 7-15 วินาที</p>
            <br />
            <Grid container direction="row" justifyContent="start">
              <Grid container  xs={12} >
                <Grid Item sx={{ padding: "0em 0.3em 0em 0em" }}>
                  <ReactPlayer
                    url={v1}
                    playing
                    controls
                    width="100%"
                    height="100%"
                    light={
                      <img
                        src={tv1}
                        alt="Thumbnail"
                        style={{
                          width: "100%",
                          height: "100%"
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container xs={9}>
                <Grid Item sx={{ padding: "0.25em 0.1em 0 0em" , alignItems:"center"}} xs={12}>
                  <ReactPlayer
                    url={v4}
                    light={
                      <img
                        src={tv4}
                        alt="Thumbnail"
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "block",
                          objectFit: "cover",
                        }}
                      />
                    }
                    playing
                    controls
                    width="100%"
                    height="auto"
                  />
                </Grid>
                <Grid Item sx={{ padding: "0.25em 0.1em 0 0em" }} xs={6}>
                  <ReactPlayer
                    url={v2}
                    light={
                      <img
                        src={tv2}
                        alt="Thumbnail"
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "block",
                          objectFit: "cover",
                        }}
                      />
                    }
                    playing
                    controls
                    width="100%"
                    height="auto"
                  />
                </Grid>
                <Grid Item sx={{ padding: "0.25em 0.1em 0 0em" }} xs={6}>
                  <ReactPlayer
                    url={v3}
                    light={
                      <img
                        src={tv3}
                        alt="Thumbnail"
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "block",
                          objectFit: "cover",
                        }}
                      />
                    }
                    playing
                    controls
                    width="100%"
                    height="auto"
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="start" item xs={3}>
                <Grid item sx={{ padding: "0.25em 0em 0em 0.1em" }}>
                  <ReactPlayer
                    url={v5}
                    playing
                    controls
                    width="99%"
                    height="99%"
                    light={
                      <img
                        src={tv5}
                        alt="Thumbnail"
                        style={{
                          width: "99%",
                          height: "99%",
                          display: "block",
                          objectFit: "cover",
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </TopWrapper>
        </Wrapper>
      </div>
    </div>
  );
}

const Wrapper = styled.section`
  width: auto;
  display: flex;
  flex-direction: column;

 
  justify-content: center;

  padding: 0 0;
`;

const TopWrapper = styled.div`
  width: 95%;
  height: auto;

  
  text-align: center;
  justify-content: center;
  padding: 20px 20px;
`;
