import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import Logo from "../../assets/img/logo.png";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Wrapper
        className="flexCenter  goldBg"
        style={y > 100 ? { height: "50px" } : { height: "70px" }}
      >
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <ImgStyle src={Logo} alt="client logo" />
          </Link>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const ImgStyle = styled.img`
  width: 70%;
  height: auto;
  max-width: 200px;
  padding: 0 0 0 2rem;
  @media (min-width: 768px) {
    max-width: 200px;
  
  }
`;
