import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import { darken } from "polished";

export default function FullButton({ title, action, border, color, to }) {
  const [y, setY] = useState(window.scrollY);

  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(color)) {
    // console.error("Invalid color format: ", color, title);
    color = "#72cd2a"; // Default color if invalid format
  }
  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <Link to={to} spy={true} smooth={true} >
      <Wrapper
        className="animate pointer radius10 semiBold font18"
        border={border}
        color={color}
      >
        {title}
      </Wrapper>
    </Link>
  );
}

const Wrapper = styled.button`
  font-family: "Kanit", sans-serif;
  border: 0px solid ${(props) => (props.border ? "#707070" : "#7620ff")};
  background-color: ${(props) => (props.border ? "transparent" : props.color)};
  width: 100%;
  padding: 4px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  :hover {
    background-color: ${(props) =>
      props.border ? "transparent" : darken(0.1, props.color)};
  }
`;
