import React from "react";
import styled from "styled-components";

export default function ProjectBox({ img }) {
  return (
    <Wrapper>
      <img src={img} alt="project" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 3px;
  padding: 0;
  aspect-ratio: 16/9;
  
  
  img {
    width: 100%;
    height: 100%;
    object-fit: stretch;
  }
`;
