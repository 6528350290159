import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact.jpg";
import c1 from "../../assets/img/company_profile/1.jpg";
import c2 from "../../assets/img/company_profile/2.jpg";
import c3 from "../../assets/img/company_profile/3.jpg";
import c4 from "../../assets/img/company_profile/4.jpg";
import c5 from "../../assets/img/company_profile/5.jpg";

export default function Contact() {
  return (
    <Wrapper className="container flexSpaceCenter" id="contact">
      
    <TopWrapper className="lightdarkBg  whiteColor">
    <p className="font20 textLeft lightgreyColor"> Company profile</p>
    <br/>
      <Carousel
        showThumbs={false}
        showStatus={false}
        autoPlay
        infiniteLoop
        interval={3000}
        transitionTime={500}
      >
        <ImgStyle src={c1} alt="Company Profile 1" />
        <ImgStyle src={c2} alt="Company Profile 2" />
        <ImgStyle src={c3} alt="Company Profile 3" />
        <ImgStyle src={c4} alt="Company Profile 4" />
        <ImgStyle src={c5} alt="Company Profile 5" />
      </Carousel>
      </TopWrapper>
    </Wrapper>
  );
}


const ImgStyle = styled.img`
  max-width: 100%;
  height: auto;
`;
const Wrapper = styled.section`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
  margin-top: 20px;

  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

const TopWrapper = styled.div`
  width: 95%;
  height: auto;
  text-align: center;
  justify-content: center;
  padding: 20px 20px;
`;
