import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import Project1 from "../components/Sections/Project1";
import Project2 from "../components/Sections/Project2";
import Project3 from "../components/Sections/Project3";

import Promotion from "../components/Sections/Promotion"
export default function Landing() {
  return (
    <>
      <TopNavbar />
      {/* <Promotion /> */}
      <Pricing />
      <Project1/>

      <Project2/>
      <Project3/>
      <Contact />
      <Footer /> 
    </>
  );
}


