import React, { useState } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import Grid from "@mui/material/Grid"; // Grid version 1

// Asset

// Asset
import v1 from "../../assets/video/16-30/1.mp4";
import v2 from "../../assets/video/16-30/2.mp4";
import v3 from "../../assets/video/16-30/3.mp4";
import v4 from "../../assets/video/16-30/4.mp4";
import v5 from "../../assets/video/16-30/5.mp4";
import v6 from "../../assets/video/16-30/6.mp4";
import tv1 from "../../assets/img/16-30 sec/1.jpg";
import tv2 from "../../assets/img/16-30 sec/2.jpg";
import tv3 from "../../assets/img/16-30 sec/3.jpg";
import tv4 from "../../assets/img/16-30 sec/4.jpg";
import tv5 from "../../assets/img/16-30 sec/5.jpg";
import tv6 from "../../assets/img/16-30 sec/6.jpg";

export default function Project3() {
  return (
    <div className="greyBg textCenter" id="project3">
      <div>
        <Wrapper className="container flexSpaceCenter">
          <TopWrapper className="lightdarkBg  whiteColor">
            <p className="font20 textLeft lightgreyColor"> 16-30 วินาที</p>
            <br />
            <Grid container alignItems="stretch">
              <Grid xs={6} sx={{ padding: "0em 0.1em 0em 0.1em" }}>
                <Grid Item>
                  <ReactPlayer
                    url={v1}
                    playing
                    controls
                    width="100%"
                    height="auto%"
                    light={
                      <img
                        src={tv1}
                        alt="Thumbnail"
                        style={{
                          width: "100%",
                          height: "auto",
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>

              <Grid xs={6}>
                <Grid Item sx={{ padding: "0em 0.1em 0em 0.1em" }}>
                  <ReactPlayer
                    url={v2}
                    light={
                      <img
                        src={tv2}
                        alt="Thumbnail"
                        style={{
                          width: "100%",
                          height: "auto",
                        }}
                      />
                    }
                    playing
                    controls
                    width="100%"
                    height="auto"
                  />
                </Grid>
              </Grid>

              <Grid xs={3}>
                <Grid Item sx={{ padding: "0.1em 0.1em 0em 0.1em" }}>
                  <ReactPlayer
                    url={v5}
                    playing
                    controls
                    width="100%"
                    height="50%"
                    light={
                      <img
                        src={tv5}
                        alt="Thumbnail"
                        style={{
                          width: "100%",
                          height: "auto%",
                          display: "block",
                          objectFit: "cover",
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                xs={3}
                sx={{
                  justifyContent: "center",
                }}
              >
                <Grid Item sx={{ padding: "0.1em 0.1em 0em 0.1em" }}>
                  <ReactPlayer
                    url={v3}
                    light={
                      <img
                        src={tv3}
                        alt="Thumbnail"
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "block",
                          objectFit: "cover",
                        }}
                      />
                    }
                    controls
                    playing
                    width="100%"
                    height="auto"
                  />
                </Grid>
              </Grid>
              <Grid
                xs={3}
                sx={{
                  justifyContent: "center",
                }}
              >
                <Grid Item sx={{ padding: "0.1em 0.1em 0em 0.1em" }}>
                  <ReactPlayer
                    url={v6}
                    light={
                      <img
                        src={tv4}
                        alt="Thumbnail"
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "block",
                          objectFit: "cover",
                        }}
                      />
                    }
                    controls
                    playing
                    width="100%"
                    height="auto"
                  />
                </Grid>
              </Grid>
              <Grid xs={3}>
                <Grid
                  container
                  alignItems="stretch"
                  Item
                  sx={{ padding: "0.1em 0.1em 0em 0.1em" }}
                >
                  <ReactPlayer
                    url={v4}
                    light={
                      <img
                        src={tv6}
                        alt="Thumbnail"
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "block",
                          objectFit: "cover",
                        }}
                      />
                    }
                    controls
                    playing
                    width="100%"
                    height="auto"
                  />
                </Grid>
              </Grid>
            </Grid>
          </TopWrapper>
        </Wrapper>
      </div>
    </div>
  );
}

const Wrapper = styled.section`
  width: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 860px) {
    flex-direction: column;
  }
  justify-content: center;

  padding: 0 0;
`;

const TopWrapper = styled.div`
  width: 95%;
  height: auto;
  text-align: center;
  justify-content: center;
  padding: 20px 20px;
`;
