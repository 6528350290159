import React from "react";
import styled from "styled-components";
import { darken } from "polished";
import { Link } from "react-scroll";

export default function InfoButton({ title, action, border, color }) {
  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(color)) {
    console.error("Invalid color format: ", color);
    color = "#72cd2a"; // Default color if invalid format
  }

  return (
    <Link to="contact" spy={true} smooth={true}>
      <Wrapper
        className="animate pointer radius10 semiBold font20"
        onClick={action ? () => action() : null}
        border={border}
        color={color}
      >
        {title}
      </Wrapper>
    </Link>
  );
}

const Wrapper = styled.button`
  font-family: "Kanit", sans-serif;
  border: 0px solid ${(props) => (props.border ? "#707070" : "#7620ff")};
  background-color: ${(props) => (props.border ? "transparent" : props.color)};
  width: 100%;
  padding: 7px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  :hover {
    background-color: ${(props) =>
      props.border ? "transparent" : darken(0.1, props.color)};
  }
`;
