import React, { useState } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";

import Grid from "@mui/material/Grid"; // Grid version 1

// Asset
import v1 from "../../assets/video/6sec/1.mp4";
import v2 from "../../assets/video/6sec/2.mp4";
import v3 from "../../assets/video/6sec/3.mp4";
import v4 from "../../assets/video/6sec/4.mp4";
import v5 from "../../assets/video/6sec/5.mp4";
import v6 from "../../assets/video/6sec/6.mp4";
import tv1 from "../../assets/img/6 sec/1.jpg";
import tv2 from "../../assets/img/6 sec/2.jpg";
import tv3 from "../../assets/img/6 sec/3.jpg";
import tv4 from "../../assets/img/6 sec/4.jpg";
import tv5 from "../../assets/img/6 sec/5.jpg";
import tv6 from "../../assets/img/6 sec/6.jpg";

export default function Project1() {
  return (
    <div className="greyBg textCenter" id="project1">
      <div>
        <Wrapper className="container flexSpaceCenter">
          <TopWrapper className="lightdarkBg  whiteColor">
            <p className="font20 textLeft lightgreyColor"> 6 วินาที</p>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "auto",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gridGap: "0.2em",
              }}
            >
              <div
                style={{
                  flexBasis: "32.1%",
                  flexGrow: 1,
                }}
              >
                <ReactPlayer
                  url={v1}
                  playing
                  controls
                  width="100%"
                  height="100%"
                  light={
                    <img
                      src={tv1}
                      alt="Thumbnail"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  }
                />
              </div>
              <div
                style={{
                  flexBasis: "34%",
                  flexGrow: 1,
                  // alignSelf: "flex-end",
                }}
              >
                <ReactPlayer
                  url={v2}
                  playing
                  controls
                  width="100%"
                  height="100%"
                  light={
                    <img
                      src={tv2}
                      alt="Thumbnail"
                      style={{
                        width: "100%",
                        height: "100%",
                        // alignSelf: "flex-start",
                      }}
                    />
                  }
                />
                <ReactPlayer
                  url={v3}
                  playing
                  controls
                  width="100%"
                  height="100%"
                  light={
                    <img
                      src={tv3}
                      alt="Thumbnail"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  }
                />

                <ReactPlayer
                  url={v4}
                  playing
                  controls
                  width="100%"
                  height="100%"
                  light={
                    <img
                      src={tv4}
                      alt="Thumbnail"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  }
                />
              </div>

              <div
                style={{
                  flexBasis: "24.8%",
                  flexGrow: 1,
                  // alignSelf: "flex-end",
                }}
              >
                <ReactPlayer
                  url={v5}
                  playing
                  controls
                  width="100%"
                  height="100%"
                  light={
                    <img
                      src={tv5}
                      alt="Thumbnail"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  }
                />
                <ReactPlayer
                  url={v6}
                  playing
                  controls
                  width="100%"
                  height="100%"
                  light={
                    <img
                      src={tv6}
                      alt="Thumbnail"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      
                    />
                  }
                />
              </div>
            </div>
          </TopWrapper>
        </Wrapper>
      </div>
    </div>
  );
}

const Wrapper = styled.section`
  width: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 860px) {
    flex-direction: column;
  }
  justify-content: center;

  padding: 0 0;
`;

const TopWrapper = styled.div`
  width: 95%;
  height: auto;

  @media (max-width: 860px) {
    flex-direction: column;
  }
  text-align: center;
  justify-content: center;
  padding: 20px 20px;
`;
