import React from "react";
import img from "../../assets/img/promotion.png";
import styled from "styled-components";

const Promotion = () => {
  return (
    <div className="container">
      <ImgStyle className="image" src={img} alt="promotion" />
    </div>
  );
};

export default Promotion;

const ImgStyle = styled.img`
  width: 15%;
  height: auto;
  @media (min-width: 768px) {
    max-width: 130px;
  }
  position: fixed;
  top: 70%;
  right: 1%;
  transform: translate(0, -50%);
  z-index: 100;
`;
